import { useCallback, useEffect, useState } from 'react'
import {
  internalAPI,
  externalAPI,
  getErrorsFromResponse,
  onLoadingError,
} from 'utils/api'

const useQuery = (url, params = undefined, apiType = 'INTERNAL') => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState()
  const [error, setError] = useState()

  const api = apiType === 'INTERNAL' ? internalAPI : externalAPI

  const fetch = useCallback(() => {
    api
      .get(url, {
        params: params,
      })
      .then((response) => {
        setData(response.data)
        setLoading(false)
      })
      .catch((error) => {
        onLoadingError(error)
        setData(undefined)
        setLoading(false)
        setError(getErrorsFromResponse(error))
      })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    fetch()
  }, [fetch])

  return {
    loading,
    setLoading,
    data,
    setData,
    error,
    fetch,
  }
}

export default useQuery
