import React, { useContext, useState, useEffect, useCallback } from 'react'

import PropTypes from 'prop-types'

import { DATA_URLS, URLS } from 'urls'
import { internalAPI, onLoadingError } from 'utils/api'
import { useIsMount } from 'utils/hooks'

export const MentionsContext = React.createContext({})

export const MentionsContextProvider = ({ children }) => {
  const isMount = useIsMount()
  const [historical, setHistorical] = useState()
  const [unread, setUnread] = useState()
  const [markingAllAsRead, setMarkingAllAsRead] = useState(false)
  const [markingAsRead, setMarkingAsRead] = useState(false)

  const getUnreadMentions = useCallback(() => {
    setUnread(undefined)
    return internalAPI
      .get(DATA_URLS.internal.mentions.unread.buildPath())
      .then((response) => {
        setUnread(response.data)
      })
      .catch(onLoadingError)
  }, [])

  const getHistoricalMentions = useCallback(() => {
    setHistorical(undefined)
    return internalAPI
      .get(DATA_URLS.internal.mentions.history.buildPath())
      .then((response) => {
        setHistorical(response.data)
      })
      .catch(onLoadingError)
  }, [])

  const markAllAsRead = () => {
    setMarkingAllAsRead(true)
    return internalAPI
      .post(DATA_URLS.internal.mentions.markAllAsRead.buildPath())
      .then(() => {
        setMarkingAllAsRead(false)
      })
      .catch((error) => {
        setMarkingAllAsRead(false)
        onLoadingError(error)
        throw error
      })
  }

  const markAsRead = (mention) => {
    setMarkingAsRead(true)
    return internalAPI
      .post(
        DATA_URLS.internal.mentions.markAsRead.buildPath({ pk: mention.id })
      )
      .then(() => {
        setMarkingAsRead(false)
      })
      .catch((error) => {
        setMarkingAsRead(false)
        onLoadingError(error)
        throw error
      })
  }

  const getHref = (mention) => {
    switch (mention.content_type__name) {
      case 'order comment':
        return URLS.orderManagement.orders.list.buildPath({
          action: 'update',
          id: mention.related_object,
        })
      case 'change request':
        return URLS.changeManagement.requests.list.buildPath({
          action: 'update',
          id: mention.related_object,
        })
      default:
        return ''
    }
  }

  useEffect(() => {
    if (isMount) {
      getUnreadMentions()
    }
  }, [isMount, getUnreadMentions])

  return (
    <MentionsContext.Provider
      value={{
        getUnreadMentions,
        getHistoricalMentions,
        markAllAsRead,
        markingAllAsRead,
        markAsRead,
        markingAsRead,
        unread,
        setUnread,
        historical,
        getHref,
      }}
    >
      {children}
    </MentionsContext.Provider>
  )
}

MentionsContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const useMentions = () => useContext(MentionsContext)
