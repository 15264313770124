import React from 'react'

import { CheckOutlined } from '@ant-design/icons'
import { Button, List, Tooltip, message } from 'antd'

import ColoredAvatar from 'components/ColoredAvatar'
import { useMentions } from 'components/MentionsContext'
import { formatMomentDateTime } from 'utils/functions'

const UnreadMentions = () => {
  const mentions = useMentions()
  return (
    <>
      {mentions.unread.length > 0 && (
        <div className="pull-right">
          <Button
            type="default"
            size="small"
            icon={<CheckOutlined />}
            loading={mentions.markingAllAsRead}
            onClick={() => {
              mentions.markAllAsRead().then(() => {
                mentions.setUnread([])
                message.success('All notifications were marked as read')
              })
            }}
          >
            Mark all as Read
          </Button>
        </div>
      )}
      <div className="clearfix" />
      <List
        dataSource={mentions.unread}
        pagination={false}
        renderItem={(item) => {
          return (
            <List.Item
              key={item.id}
              actions={[
                <span key="mark-as-read">
                  <Tooltip title="Mark as read">
                    <Button
                      icon={<CheckOutlined />}
                      shape="circle"
                      onClick={() => {
                        mentions.markAsRead(item).then(() => {
                          mentions.setUnread((value) => {
                            if (value) {
                              return value.filter(
                                (mention) => mention.id !== item.id
                              )
                            }
                          })
                          message.success('Notification was marked as read')
                        })
                      }}
                    />
                  </Tooltip>
                </span>,
              ]}
            >
              <List.Item.Meta
                avatar={<ColoredAvatar value={item.mentioned_by__full_name} />}
                title={
                  item.content_type__name === 'user invitation' ? (
                    <>
                      {item.mentioned_by__full_name} invited you to{' '}
                      <strong>{item.content_object__str}</strong>
                    </>
                  ) : (
                    <a
                      href={mentions.getHref(item)}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {item.mentioned_by__full_name} mentioned you in{' '}
                      <strong>{item.content_object__str}</strong>
                    </a>
                  )
                }
                description={formatMomentDateTime(item.date_created)}
              />
            </List.Item>
          )
        }}
      />
    </>
  )
}

export default UnreadMentions
