import axios from 'axios'

import { notification } from 'antd'

import { getEnvConfig } from 'env'

const config = getEnvConfig()
const isBrowserTest = localStorage.getItem('is_browser_test') === 'true'
const testServerUrl = localStorage.getItem('live_server')

export const apiUrl =
  config.environment === 'local' && isBrowserTest
    ? testServerUrl
    : config.apiUrl

export const internalAPI = axios.create({
  baseURL: `${apiUrl}api/internal/v1/`,
  timeout: 600000,
})

export const externalAPI = axios.create({
  baseURL: `${apiUrl}api/external/v1/`,
  timeout: 600000,
})

for (const api of [internalAPI, externalAPI]) {
  api.interceptors.request.use((config) => {
    const user = JSON.parse(localStorage.getItem('user')) || {}

    if (user.token) {
      const headers = {
        ...config.headers,
        Authorization: `Token ${user.token}`,
      }
      return { ...config, headers }
    }

    return config
  })
}

export function getErrorsFromResponse(error) {
  if (error.message === 'Canceled') {
    return
  }

  const { response } = error

  if (!response) {
    console.error(error)
    return
  }

  let errors = {}

  if (response.data.detail) {
    errors.non_field_errors = [response.data.detail]
  } else {
    errors = response ? response.data.errors || response.data : error
  }

  return errors
}

export function onLoadingError(error, msg) {
  if (error.response && error.response.data) {
    const data = error.response.data

    let errorMessage

    if (error.response.status === 404) {
      errorMessage = '404 - Route not found'
    } else {
      if (data.non_field_errors) {
        errorMessage = data.non_field_errors.join(', ')
      } else if (data.detail) {
        errorMessage = data.detail
      } else {
        errorMessage = JSON.stringify(data)
      }
    }

    notification.error({
      message: msg || 'Error',
      description: errorMessage,
      duration: null,
    })
  }
  console.error(error)
  console.trace()
}
