import React from 'react'

import { Link } from 'react-router-dom'
import { Result, Button } from 'antd'

import { URLS } from 'urls'

const Forbidden = () => {
  return (
    <Result
      status="403"
      title="403"
      subTitle="Sorry, you don't have access to that page."
      extra={
        <Link to={URLS.dashboard.index.buildPath()}>
          <Button type="primary">Back to Index</Button>
        </Link>
      }
    />
  )
}

export default Forbidden
