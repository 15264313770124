import React, { useState, useEffect } from 'react'

import { withRouter, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

import { useAuth } from 'containers/Auth/AuthContext'
import LoadingProgress from 'components/LoadingProgress'
import { externalAPI } from 'utils/api'
import { DATA_URLS } from 'urls'

import SuperAdminMenuItems from './SuperAdminMenuItems'
import MenuItems from './MenuItems'

const MainNavigation = (props) => {
  const { enableSupportSection } = props
  const location = useLocation()
  const { selectedMembership, company } = useAuth()

  const [openKeys, setOpenKeys] = useState(undefined)
  const [selectedKeys, setSelectedKeys] = useState([])
  const [defaultOpenKeys, setDefaultOpenKeys] = useState([])
  const [customerHasManagedServices, setCustomerHasManagedServices] =
    useState(undefined)

  const getKeyParts = (key) => {
    if (key === '/') return []
    return key.split('/').filter((p) => p !== '')
  }

  const onOpenChange = (currentlyOpenKeys) => {
    setOpenKeys(currentlyOpenKeys)
  }

  const onMenuItemClick = ({ key }) => {
    const keyParts = getKeyParts(key)

    setOpenKeys((openKeys) => {
      if (openKeys) {
        if (keyParts.length === 1) {
          return openKeys.filter((k) => {
            return k === `/${keyParts[0]}/`
          })
        } else {
          return openKeys.filter((k) => {
            return (
              k === `/${keyParts[0]}/` ||
              k === `/${keyParts[0]}/${keyParts[1]}/`
            )
          })
        }
      }
      return openKeys
    })
  }

  const checkIfCustomerHasManagedServices = () => {
    externalAPI
      .get(DATA_URLS.external.managedServices.hasManagedServices.buildPath())
      .then((response) => {
        setCustomerHasManagedServices(response.data.has_managed_services)
      })
      .catch(() => {
        setCustomerHasManagedServices(false)
      })
  }

  const optionalMenuProps = {}

  useEffect(() => {
    if (
      selectedMembership &&
      (selectedMembership.groupCodename === 'ADMIN' ||
        selectedMembership.groupCodename === 'USER' ||
        selectedMembership.groupCodename === 'GUEST')
    ) {
      checkIfCustomerHasManagedServices()
    }
  }, [selectedMembership, selectedMembership.groupCodename])

  useEffect(() => {
    let pathNameParts = getKeyParts(location.pathname)
    let newSelectedKeys
    let newDefaultOpenKeys

    switch (pathNameParts.length) {
      case 0:
        newSelectedKeys = ['/']
        break
      case 1:
        newDefaultOpenKeys = [`/${pathNameParts[0]}/`]
        newSelectedKeys = newDefaultOpenKeys
        break
      case 2:
        newDefaultOpenKeys = [`/${pathNameParts[0]}/`]
        newSelectedKeys = [
          `/${pathNameParts[0]}/`,
          `/${pathNameParts[0]}/${pathNameParts[1]}/`,
        ]
        break
      default:
        newDefaultOpenKeys = [
          `/${pathNameParts[0]}/`,
          `/${pathNameParts[0]}/${pathNameParts[1]}/`,
        ]
        newSelectedKeys = [
          `/${pathNameParts[0]}/`,
          `/${pathNameParts[0]}/${pathNameParts[1]}/`,
          `/${pathNameParts[0]}/${pathNameParts[1]}/${pathNameParts[2]}/`,
        ]
    }
    setDefaultOpenKeys(newDefaultOpenKeys)
    setSelectedKeys(newSelectedKeys)
  }, [location.pathname])

  if (openKeys === undefined) {
    optionalMenuProps.openKeys = defaultOpenKeys
  } else {
    optionalMenuProps.openKeys = openKeys
  }

  if (selectedMembership.groupCodename === 'SUPERADMIN') {
    return (
      <SuperAdminMenuItems
        defaultOpenKeys={defaultOpenKeys}
        defaultSelectedKeys={selectedKeys}
        selectedKeys={selectedKeys}
        onOpenChange={onOpenChange}
        onMenuItemClick={onMenuItemClick}
        optionalMenuProps={optionalMenuProps}
      />
    )
  }

  if (
    customerHasManagedServices === undefined &&
    (selectedMembership.groupCodename === 'ADMIN' ||
      selectedMembership.groupCodename === 'USER' ||
      selectedMembership.groupCodename === 'GUEST')
  ) {
    return <LoadingProgress />
  }

  if (
    (selectedMembership.groupCodename === 'ADMIN' ||
      selectedMembership.groupCodename === 'USER' ||
      selectedMembership.groupCodename === 'GUEST') &&
    company.type === 'PARTNER'
  ) {
    return (
      <MenuItems
        defaultOpenKeys={defaultOpenKeys}
        defaultSelectedKeys={selectedKeys}
        selectedKeys={selectedKeys}
        onOpenChange={onOpenChange}
        onMenuItemClick={onMenuItemClick}
        optionalMenuProps={optionalMenuProps}
        enableManagedServicesSection={customerHasManagedServices}
      />
    )
  }
  if (
    (selectedMembership.groupCodename === 'ADMIN' ||
      selectedMembership.groupCodename === 'USER' ||
      selectedMembership.groupCodename === 'GUEST') &&
    company.type === 'CLIENT'
  ) {
    return (
      <MenuItems
        defaultOpenKeys={defaultOpenKeys}
        defaultSelectedKeys={selectedKeys}
        selectedKeys={selectedKeys}
        enableSupportSection={enableSupportSection}
        onOpenChange={onOpenChange}
        onMenuItemClick={onMenuItemClick}
        optionalMenuProps={optionalMenuProps}
        enableManagedServicesSection={customerHasManagedServices}
      />
    )
  }
  if (selectedMembership.groupCodename === 'NO_ACCESS') {
    return <div />
  }

  return <LoadingProgress />
}

MainNavigation.propTypes = {
  location: PropTypes.object.isRequired,
  enableSupportSection: PropTypes.bool,
}

export default withRouter(MainNavigation)
