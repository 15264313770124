import React from 'react'

import PropTypes from 'prop-types'

import LoadingProgress from 'components/LoadingProgress'
import InternalError from 'containers/Exceptions/InternalError'
import Forbidden from 'containers/Exceptions/Forbidden'

export const LoadingComponent = ({ isLoading, error }) => {
  if (isLoading) {
    return <LoadingProgress />
  } else if (error) {
    const status = error.response?.status
    if (status === 403) {
      return <Forbidden />
    }
    return <InternalError />
  } else {
    return null
  }
}

LoadingComponent.propTypes = {
  isLoading: PropTypes.bool,
  error: PropTypes.any,
}
