import React, { useCallback, useContext, useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import ReconnectingWebSocket from 'reconnecting-websocket'

import { useApp } from 'containers/Apps/AppContext'

export const WebSocketContext = React.createContext({})

export const WebSocketContextProvider = ({
  children,
  url,
  onClose,
  onMessage,
  onError,
}) => {
  const app = useApp()
  const [connecting, setConnecting] = useState(false)
  const [socket, setSocket] = useState()

  const connect = useCallback(() => {
    if (!url || socket || connecting) {
      return
    }

    setConnecting(true)
    const user = JSON.parse(localStorage.getItem('user')) || {}
    const protocol = app.config.apiUrl.startsWith('https://') ? 'wss' : 'ws'
    const newSocket = new ReconnectingWebSocket(
      `${protocol}://${app.config.apiUrl
        .replace('http://', '')
        .replace('https://', '')
        .replace(/\/$/, '')}${url}?token=${user.token}`
    )

    newSocket.onopen = () => {
      if (onClose) {
        newSocket.onclose = onClose
      }

      if (onMessage) {
        newSocket.onmessage = (message) => {
          return onMessage(JSON.parse(message.data))
        }
      }

      if (onError) {
        newSocket.onerror = onError
      }
      setSocket(newSocket)
      setConnecting(false)
    }
  }, [connecting, socket, url, onClose, onMessage, onError, app.config.apiUrl])

  const disconnect = () => {
    if (socket) {
      socket.close()
    } else {
      console.error('Socket was not initialized')
    }
  }

  const send = (message) => {
    if (socket) {
      socket.send(message)
    } else {
      console.error('Socket was not initialized')
    }
  }

  const reRegisterOnMessage = (callback) => {
    socket.onmessage = (message) => {
      return callback(JSON.parse(message.data))
    }
  }

  useEffect(() => {
    connect()
  }, [connect])

  return (
    <WebSocketContext.Provider
      value={{
        disconnect,
        reRegisterOnMessage,
        send,
      }}
    >
      {children}
    </WebSocketContext.Provider>
  )
}

WebSocketContextProvider.propTypes = {
  onMessage: PropTypes.func,
  url: PropTypes.string,
  onClose: PropTypes.func,
  onError: PropTypes.func,
  children: PropTypes.node,
}

export const useWebSocket = () => useContext(WebSocketContext)
