import React from 'react'

import { Link } from 'react-router-dom'
import { Result, Button } from 'antd'

import { URLS } from 'urls'

const InternalError = () => {
  return (
    <Result
      status="500"
      title="500"
      subTitle="Sorry, the page can not be displayed due to internal error. We are already know about the problem and working on it right now."
      extra={
        <Link to={URLS.dashboard.index.buildPath()}>
          <Button type="primary">Back to Index</Button>
        </Link>
      }
    />
  )
}

export default InternalError
