import React from 'react'

import { Layout } from 'antd'

import { publicRoutes } from 'routes'

const PublicApp = () => {
  return <Layout>{publicRoutes}</Layout>
}

export default PublicApp
