import React from 'react'

import { Avatar, Tooltip } from 'antd'
import PropTypes from 'prop-types'
import tinycolor from 'tinycolor2'

import { EMPTY_TEXT } from 'utils/constants'
import { stringToColor } from 'utils/functions'

import styles from './index.module.less'

const ColoredAvatar = ({ value, size, showValue, subtitle, showTooltip }) => {
  if (!value) {
    return EMPTY_TEXT
  }
  const [firstName, lastName] = value ? value.split(' ') : ['Unknown', '']
  const hexValue = stringToColor(value)
  const parsedColor = tinycolor(hexValue)
  const avatar = (
    <Avatar
      size={size}
      style={{
        color: parsedColor.isLight() ? '#000' : '#fff',
        backgroundColor: stringToColor(value),
        marginRight: 0,
      }}
    >
      {firstName[0]}
      {lastName ? `${lastName[0]}` : ''}
    </Avatar>
  )
  if (!showValue) {
    if (showTooltip) {
      return <Tooltip title={value}>{avatar}</Tooltip>
    }
    return avatar
  }

  if (subtitle) {
    return (
      <span>
        <div className={styles.avatar}>
          <span style={{ marginRight: 3 }}>{avatar}</span>
        </div>
        <div className={styles.value}>
          <div>
            <span>{value}</span>
          </div>
          <div className={styles.subtitle}>
            <span>{subtitle}</span>
          </div>
        </div>
        <div className="clearfix" />
      </span>
    )
  }
  return (
    <span>
      <span style={{ marginRight: 3 }}>{avatar}</span> <span>{value}</span>
    </span>
  )
}

ColoredAvatar.propTypes = {
  value: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  size: PropTypes.string,
  showValue: PropTypes.bool,
  showTooltip: PropTypes.bool,
}

ColoredAvatar.defaultProps = {
  size: 'default',
  showValue: false,
  showTooltip: true,
}

export default ColoredAvatar
