import React, { useCallback, useContext, useEffect, useState } from 'react'

import { useMsal } from '@azure/msal-react'
import { InteractionRequiredAuthError } from '@azure/msal-browser'
import PropTypes from 'prop-types'
import { useCookies } from 'react-cookie'

import { DATA_URLS } from 'urls'
import { internalAPI, getErrorsFromResponse, onLoadingError } from 'utils/api'
import { parseJWT } from 'utils/functions'

export const AuthContext = React.createContext({})

export const AuthContextProvider = ({ children }) => {
  const [cookies] = useCookies()
  const { instance: msalInstance, accounts: msalAccounts } = useMsal()
  const storedUser = localStorage.getItem('user')
  const [authToken, setAuthToken] = useState(
    storedUser ? JSON.parse(storedUser).token : undefined
  )
  const [user, setUser] = useState({
    id: undefined,
    username: undefined,
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    fullName: undefined,
  })
  const [loginOwner, setLoginOwner] = useState({
    username: undefined,
    fullName: undefined,
  })
  const [selectedMembership, setSelectedMembership] = useState({
    groupCodename: undefined,
    contactName: undefined,
  })
  const [membership, setMembership] = useState()
  const [company, setCompany] = useState({
    name: undefined,
    type: undefined,
    logo: undefined,
    primaryColor: undefined,
    secondaryColor: undefined,
    enableSupportSection: undefined,
    enableRealtimeUpdates: undefined,
    enableAPIAccess: undefined,
    enableDDoSProtectIntegration: undefined,
  })
  const [accessToken, setAccessToken] = useState(undefined)
  const [idToken, setIDToken] = useState(undefined)
  const [idTokenValidating, setIDTokenValidating] = useState(true)
  const [idTokenValidationError, setIDTokenValidationError] = useState()

  const validateIDToken = useCallback((idToken) => {
    setIDTokenValidating(true)
    return internalAPI
      .post(DATA_URLS.internal.auth.validateADToken.buildPath(), {
        token: idToken,
      })
      .then((response) => {
        const user = {
          token: response.data.token,
          username: response.data.username,
        }
        localStorage.setItem('user', JSON.stringify(user))
        setUser((value) => {
          return {
            ...value,
            username: user.username,
          }
        })
        setAuthToken(user.token)
        setIDTokenValidating(false)
      })
      .catch((error) => {
        const errors = getErrorsFromResponse(error)
        setIDTokenValidating(false)
        setIDTokenValidationError(errors)
      })
  }, [])

  const loginAs = (user, config) => {
    internalAPI
      .post(DATA_URLS.internal.auth.loginAs.buildPath(), {
        user: user.id,
      })
      .then(() => {
        config.onSuccess()
      })
      .catch(onLoadingError)
  }

  const reLoginAsSelf = (config) => {
    internalAPI
      .post(DATA_URLS.internal.auth.reLoginAsSelf.buildPath())
      .then(() => {
        config.onSuccess()
      })
      .catch(onLoadingError)
  }

  const login = (loginParams = {}) => {
    msalInstance.loginRedirect(loginParams)
  }

  const logout = () => {
    if (loginOwner.username) {
      reLoginAsSelf({
        onSuccess: () => {
          msalInstance.logout().then(() => {
            localStorage.removeItem('user')
          })
        },
      })
    } else {
      msalInstance.logout().then(() => {
        localStorage.removeItem('user')
      })
    }
  }

  const getCurrentUser = useCallback(() => {
    internalAPI
      .get(DATA_URLS.internal.auth.userInfo.buildPath())
      .then((response) => {
        const { user, membership, company, login_owner } = response.data
        const selectedMembership = membership.filter((m) => m.is_selected)[0]
        setUser({
          id: user.id,
          username: user.username,
          firstName: user.first_name,
          lastName: user.last_name,
          email: user.email,
          fullName: user.full_name,
        })
        setMembership(
          membership.map((m) => {
            return {
              groupCodename: m.group?.codename,
              permissions: m.group?.permissions.map((p) => p.codename),
              contactName: m.contact?.name,
              companyID: m.company?.id,
              companyName: m.company?.name,
              isSelected: m.is_selected,
            }
          })
        )
        setSelectedMembership({
          companyName: selectedMembership.company?.name,
          groupCodename: selectedMembership.group?.codename,
          permissions: selectedMembership.group?.permissions.map(
            (p) => p.codename
          ),
          contactName: selectedMembership.contact?.name,
        })
        setCompany({
          id: company ? company.id : null,
          name: company ? company.name : null,
          type: company ? company.type : null,
          primaryColor: company ? company.primary_color : null,
          secondaryColor: company ? company.secondary_color : null,
          logo: company ? company.logo : null,
          enableSupportSection: company
            ? company.enable_connectwise_support
            : false,
          enableRealtimeUpdates: company
            ? company.enable_realtime_updates
            : false,
          enableAPIAccess: company ? company.enable_api_access : false,
          enableDDoSProtectIntegration: company
            ? company.enable_ddos_protect_integration
            : false,
        })
        setLoginOwner(
          login_owner
            ? {
                username: login_owner.username,
                fullName: login_owner.full_name,
              }
            : {
                username: undefined,
                fullName: undefined,
              }
        )
      })
      .catch(onLoadingError)
  }, [])

  const changePassword = (data, config) => {
    internalAPI
      .post(DATA_URLS.internal.auth.changePassword.buildPath(), {
        new_password: data.new_password,
        current_password: data.current_password,
      })
      .then(() => {
        config.onSuccess()
      })
      .catch((error) => {
        config.onError({ errors: getErrorsFromResponse(error) })
      })
  }

  const updateProfile = (data, config) => {
    internalAPI
      .patch(DATA_URLS.internal.auth.userInfo.buildPath(), data)
      .then(() => {
        config.onSuccess()
      })
      .catch((error) => {
        config.onError({ errors: getErrorsFromResponse(error) })
      })
  }

  const getRedirectUri = () => {
    return localStorage.getItem('redirectUri')
  }

  const logActivity = () => {
    internalAPI
      .post(DATA_URLS.internal.auth.logActivity.buildPath(), {})
      .catch((error) => {
        console.error(error)
      })
  }

  const sendAccessNotification = () => {
    return internalAPI.post(
      DATA_URLS.internal.auth.accessNotification.buildPath(),
      {}
    )
  }

  const switchCompany = (companyId) => {
    return internalAPI.post(DATA_URLS.internal.auth.switchCompany.buildPath(), {
      company: companyId,
    })
  }

  const getMSALRequestParams = () => {
    const clientId = msalInstance.config.auth.clientId
    const requestStateCookie = cookies[`msal.${clientId}.request.params`]
    return parseJWT(requestStateCookie)
  }

  useEffect(() => {
    if (msalAccounts.length > 0) {
      const request = {
        scopes: ['User.Read'],
        account: msalAccounts[0],
      }
      msalInstance
        .acquireTokenSilent(request)
        .then((response) => {
          setAccessToken(response.accessToken)
          setIDToken(response.idToken)
          validateIDToken(response.idToken).then(() => {
            getCurrentUser()
          })
        })
        .catch((error) => {
          // acquireTokenSilent can fail for a number of reasons, fallback to interaction
          if (error instanceof InteractionRequiredAuthError) {
            msalInstance.acquireTokenRedirect(request).then((response) => {
              setAccessToken(response.accessToken)
              setIDToken(response.idToken)
              validateIDToken(response.idToken).then(() => {
                getCurrentUser()
              })
            })
          }
        })
    }
  }, [getCurrentUser, validateIDToken, msalInstance, msalAccounts])

  return (
    <AuthContext.Provider
      value={{
        accessToken,
        idToken,
        idTokenValidating,
        idTokenValidationError,
        authToken,
        user,
        loginOwner,
        membership,
        selectedMembership,
        company,
        loginAs,
        reLoginAsSelf,
        login,
        logout,
        getCurrentUser,
        changePassword,
        updateProfile,
        getRedirectUri,
        logActivity,
        sendAccessNotification,
        switchCompany,
        getMSALRequestParams,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const useAuth = () => useContext(AuthContext)
