import React, { createContext, useContext, useEffect } from 'react'
import { DATA_URLS } from 'urls'
import useQuery from 'components/Data/hooks/useQuery'

import styles from './index.module.less'

export const ServiceMessageContext = createContext({
  isServiceMessagePresent: false,
  setIsServiceMessagePresent: () => {},
})

const ServiceMessage = () => {
  const { isServiceMessagePresent, setIsServiceMessagePresent } = useContext(
    ServiceMessageContext
  )
  const handleClose = (msgId) => {
    if (msgId) {
      localStorage.setItem('closedServiceMessage', msgId)
    }
    setIsServiceMessagePresent(false)
  }
  const serviceMessage = useQuery(
    DATA_URLS.external.serviceMessages.latest.buildPath(),
    {},
    'EXTERNAL'
  )
  let className = styles.msgInfo
  if (serviceMessage.data?.level === 'WARNING') {
    className = styles.msgWarning
  } else if (serviceMessage.data?.level === 'ERROR') {
    className = styles.msgError
  }
  useEffect(() => {
    setIsServiceMessagePresent(
      serviceMessage.data?.id &&
        localStorage.getItem('closedServiceMessage') !== serviceMessage.data?.id
    )
  }, [serviceMessage.loading, serviceMessage, setIsServiceMessagePresent])
  return (
    <div className={isServiceMessagePresent ? className : styles.msgAbsent}>
      <div className={styles.blockStart} />
      <div className={styles.msgContainer}>{serviceMessage.data?.message}</div>
      <div
        onClick={() => handleClose(serviceMessage.data?.id)}
        className={styles.btnClose}
      >
        x
      </div>
    </div>
  )
}

export default ServiceMessage
