import React, { useContext } from 'react'

import PropTypes from 'prop-types'

import { getEnvConfig } from 'env'

export const AppContext = React.createContext({
  config: null,
})

export const AppContextProvider = ({ children }) => {
  return (
    <AppContext.Provider
      value={{
        config: getEnvConfig(),
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

AppContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const useApp = () => useContext(AppContext)
