export function getEnvConfig() {
  return {
    environment: process.env.REACT_APP_ENVIRONMENT,

    // URLs.
    url: process.env.REACT_APP_URL,
    apiUrl: process.env.REACT_APP_API_URL,
    netboxUrl: process.env.REACT_APP_NETBOX_URL,

    // Azure auth.
    azureAuth: {
      auth: {
        authority: 'https://login.microsoftonline.com/common',
        clientId: process.env.REACT_APP_AZURE_CLIENT_ID,
        redirectUri: `${process.env.REACT_APP_URL}auth-callback`,
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true,
      },
    },

    // Sentry.
    sentryDSN: process.env.REACT_APP_SENTRY_DSN,

    // Google.
    googleAnalyticsTracker: process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKER,
    googleMapsKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,

    // Other.
    trackUserActivity: process.env.REACT_APP_TRACK_USER_ACTIVITY === 'on',
    appVersion: process.env.REACT_APP_VERSION,
  }
}
