import React, { useEffect } from 'react'

import ReactGA from 'react-ga'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

import { useApp } from 'containers/Apps/AppContext'
import { useAuth } from 'containers/Auth/AuthContext'

const GoogleAnalytics = ({ location }) => {
  const { config } = useApp()
  const { user } = useAuth()

  useEffect(() => {
    if (config.googleAnalyticsTracker) {
      ReactGA.initialize(config.googleAnalyticsTracker, {
        gaOptions: {
          userId: user.id,
        },
      })
      ReactGA.pageview(location.pathname + location.search)
    }
  }, [user.id, config.googleAnalyticsTracker, location])

  return <></>
}

GoogleAnalytics.propTypes = {
  location: PropTypes.object.isRequired,
}

export default withRouter(GoogleAnalytics)
