import React, { useState, useEffect } from 'react'

import { Button } from 'antd'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'

import Icon from 'components/Icon'
import { useAuth } from 'containers/Auth/AuthContext'
import { APP_TITLE } from 'utils/constants'

const LoginPage = () => {
  const { login } = useAuth()
  const [isPending, setIsPending] = useState(false)

  useEffect(() => {
    document.body.classList.add('splash-page')
  }, [])

  return (
    <div className={'auth-wrapper'}>
      <Helmet>
        <title>Login | {APP_TITLE}</title>
      </Helmet>
      <div className="login-form-wrapper">
        <div className="netportal-auth-logo" />
        <Button
          loading={isPending}
          block
          type="primary"
          size="large"
          icon={<Icon icon="faWindows" brand />}
          onClick={() => {
            setIsPending(true)
            login()
          }}
        >
          Login with Microsoft
        </Button>
      </div>
    </div>
  )
}

LoginPage.propTypes = {
  login: PropTypes.func.isRequired,
}

export default LoginPage
