import React from 'react'

import { UserSwitchOutlined } from '@ant-design/icons'
import { Alert, Button, Table, Tag, Badge, notification } from 'antd'

import { useAuth } from 'containers/Auth/AuthContext'
import { EMPTY_TEXT } from 'utils/constants'

import styles from './index.module.less'

const CompanySwitcher = () => {
  const auth = useAuth()
  return (
    <div>
      <Alert
        style={{ marginBottom: 10 }}
        type="info"
        message="Select the switch button below to login to an alternate organisation"
        showIcon
      />
      <Table
        dataSource={auth.membership}
        bordered
        columns={[
          {
            dataIndex: 'companyName',
            title: 'Organisation',
            render: (text) => text || EMPTY_TEXT,
          },
          {
            dataIndex: 'groupCodename',
            title: 'Group',
            align: 'center',
            width: 100,
            render: (text) => <Tag>{text}</Tag>,
          },
          {
            dataIndex: 'isSelected',
            title: 'Current',
            align: 'center',
            width: 100,
            render: (text) => {
              if (text) {
                return (
                  <Badge status="success" className={styles.selectedBadge} />
                )
              }
              return <Badge status="default" className={styles.selectedBadge} />
            },
          },
          {
            dataIndex: 'id',
            title: '',
            width: 80,
            align: 'center',
            render: (text, row) => (
              <Button
                size="small"
                disabled={row.isSelected}
                icon={<UserSwitchOutlined />}
                onClick={() => {
                  auth
                    .switchCompany(row.companyID)
                    .then(() => {
                      document.location.href = '/'
                    })
                    .catch(() => {
                      notification.error({
                        message: 'Unable to switch organisation',
                        description: 'Please try again later',
                      })
                    })
                }}
              >
                Switch
              </Button>
            ),
          },
        ]}
        size="small"
        pagination={{
          hideOnSinglePage: true,
        }}
      />
    </div>
  )
}

export default CompanySwitcher
