import React, { useEffect } from 'react'

const LocationCatcher = () => {
  const location = document.location
  const { pathname, search } = location

  useEffect(() => {
    const redirectUri = `${pathname}${search}`
    if (redirectUri && redirectUri[0] === '/') {
      localStorage.setItem('redirectUri', redirectUri)
    }
  }, [pathname, search])

  return <React.Fragment />
}

export default LocationCatcher
