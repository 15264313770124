import React from 'react'

import PropTypes from 'prop-types'
import { Alert, Tabs } from 'antd'

import useQuery from 'components/Data/hooks/useQuery'
import LoadingProgress from 'components/LoadingProgress'
import { DATA_URLS } from 'urls'

const ReleaseNotes = ({ section }) => {
  const releaseNotes = useQuery(
    section === 'STAFF_UI'
      ? DATA_URLS.internal.releaseNotes.buildPath()
      : DATA_URLS.external.releaseNotes.buildPath(),
    {},
    section === 'STAFF_UI' ? 'INTERNAL' : 'EXTERNAL'
  )

  if (releaseNotes.loading) {
    return <LoadingProgress />
  }

  if (releaseNotes.data.length === 0) {
    return <Alert message="There is no any updates yet" type="info" showIcon />
  }

  return (
    <Tabs tabPosition="left" defaultActiveKey={releaseNotes.data[0].id}>
      {releaseNotes.data.map((note) => {
        return (
          <Tabs.TabPane tab={note.title} key={note.id}>
            <div dangerouslySetInnerHTML={{ __html: note.body }} />
          </Tabs.TabPane>
        )
      })}
    </Tabs>
  )
}

ReleaseNotes.propTypes = {
  section: PropTypes.string.isRequired,
}

export default ReleaseNotes
