import React from 'react'

import { Icon as LegacyIcon } from '@ant-design/compatible'
import { Spin } from 'antd'
import classNames from 'classnames'

import styles from './index.module.less'

const LoadingProgress = () => {
  const icon = <LegacyIcon type={'loading'} style={{ fontSize: 24 }} spin />

  return (
    <div className={classNames(styles.loadingProgress, 'loading-progress')}>
      <Spin indicator={icon} />
    </div>
  )
}

export default LoadingProgress
