import React, { useState } from 'react'

import { NotificationOutlined, HistoryOutlined } from '@ant-design/icons'
import { Skeleton, Tabs } from 'antd'

import { useMentions } from 'components/MentionsContext'

import UnreadMentions from './UnreadMentions'
import MentionsHistory from './MentionsHistory'

const UserNotifications = () => {
  const mentions = useMentions()
  const [activeTab, setActiveTab] = useState()

  return (
    <div>
      <Tabs defaultActiveKey="unread" onChange={setActiveTab}>
        <Tabs.TabPane
          tab={
            <span>
              <NotificationOutlined /> Unread
            </span>
          }
          key="unread"
        >
          {mentions.unread === undefined && (
            <>
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
            </>
          )}
          {mentions.unread && <UnreadMentions />}
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <span>
              <HistoryOutlined /> History
            </span>
          }
          key="history"
        >
          {activeTab === 'history' && <MentionsHistory />}
        </Tabs.TabPane>
      </Tabs>
    </div>
  )
}

export default UserNotifications
