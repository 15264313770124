import React, { useContext, useState } from 'react'

import PropTypes from 'prop-types'
import { Grid } from 'antd'
import useMobileDetect from 'use-mobile-detect-hook'

export const LayoutContext = React.createContext({})

export const LAYOUT_MODE = {
  FULLSCREEN: 'FULLSCREEN',
  DEFAULT: 'DEFAULT',
}

export const LayoutContextProvider = ({ children }) => {
  const [mode, setMode] = useState(LAYOUT_MODE.DEFAULT)
  const { useBreakpoint } = Grid
  const supportedScreens = Object.entries(useBreakpoint())
    .filter(([, supported]) => supported)
    .map(([screen]) => screen)
  const detectMobile = useMobileDetect()
  return (
    <LayoutContext.Provider
      value={{
        mode,
        setMode,
        supportedScreens,
        detectMobile,
      }}
    >
      {children}
    </LayoutContext.Provider>
  )
}

LayoutContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const useLayout = () => useContext(LayoutContext)
