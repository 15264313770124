import React from 'react'

import { NavLink } from 'react-router-dom'
import { Menu } from 'antd'
import PropTypes from 'prop-types'

import Icon from 'components/Icon'
import { URLS } from 'urls'

const SuperAdminMenuItems = (props) => {
  const {
    defaultOpenKeys,
    defaultSelectedKeys,
    selectedKeys,
    onOpenChange,
    optionalMenuProps,
    onMenuItemClick,
  } = props

  return (
    <Menu
      mode="inline"
      theme={'light'}
      defaultOpenKeys={defaultOpenKeys}
      defaultSelectedKeys={defaultSelectedKeys}
      selectedKeys={selectedKeys}
      onOpenChange={onOpenChange}
      onClick={onMenuItemClick}
      style={{ height: '100%', borderRight: 0 }}
      {...optionalMenuProps}
    >
      <Menu.Item key={URLS.dashboard.index.buildPath()}>
        <NavLink to={URLS.dashboard.index.buildPath()}>
          <span>
            <Icon icon={'faTachometerAlt'} />
            <span>Dashboard</span>
          </span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key={URLS.locationSearch.key.buildPath()}>
        <NavLink to={URLS.locationSearch.index.buildPath()}>
          <Icon icon="faMapMarker" pro />
          <span>Address Search</span>
        </NavLink>
      </Menu.Item>
      <Menu.SubMenu
        key={URLS.orderManagement.key.buildPath()}
        title={
          <span>
            <Icon icon={'faDollarSign'} />
            <span>Orders</span>
          </span>
        }
      >
        <Menu.Item key={URLS.orderManagement.orders.list.buildPath()}>
          <NavLink to={URLS.orderManagement.orders.list.buildPath()}>
            <Icon icon={'faListAlt'} />
            <span>Orders</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key={URLS.orderManagement.workflows.index.buildPath()}>
          <NavLink to={URLS.orderManagement.workflows.index.buildPath()}>
            <Icon icon={'faTrello'} brand /> <span>Workflows</span>
          </NavLink>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu
        key={URLS.serviceManagement.key.buildPath()}
        title={
          <span>
            <Icon icon={'faNetworkWired'} />
            <span>Services</span>
          </span>
        }
      >
        <Menu.Item key={URLS.serviceManagement.circuits.list.buildPath()}>
          <NavLink to={URLS.serviceManagement.circuits.list.buildPath()}>
            <Icon icon={'faEthernet'} /> <span>Circuits</span>
          </NavLink>
        </Menu.Item>
        <Menu.SubMenu
          key={URLS.serviceManagement.devicesAndContracts.key.buildPath()}
          title={
            <span>
              <Icon icon={'faHdd'} /> <span>Devices</span>
            </span>
          }
        >
          <Menu.Item
            key={URLS.serviceManagement.devicesAndContracts.devices.list.buildPath()}
          >
            <NavLink
              to={URLS.serviceManagement.devicesAndContracts.devices.list.buildPath()}
            >
              Devices
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key={URLS.serviceManagement.devicesAndContracts.maintenanceContracts.list.buildPath()}
          >
            <NavLink
              to={URLS.serviceManagement.devicesAndContracts.maintenanceContracts.list.buildPath()}
            >
              Maintenance Contracts
            </NavLink>
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.Item key={URLS.serviceManagement.locations.list.buildPath()}>
          <NavLink to={URLS.serviceManagement.locations.list.buildPath()}>
            <Icon icon={'faMapMarker'} /> <span>Locations</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key={URLS.serviceManagement.services.list.buildPath()}>
          <NavLink to={URLS.serviceManagement.services.list.buildPath()}>
            <Icon icon={'faNetworkWired'} /> <span>Services</span>
          </NavLink>
        </Menu.Item>
        <Menu.SubMenu
          key={URLS.serviceManagement.mobile.key.buildPath()}
          title={
            <span>
              <Icon icon={'faMobileAlt'} /> <span>Mobile M2M</span>
            </span>
          }
        >
          <Menu.Item
            key={URLS.serviceManagement.mobile.cellularSIMCards.list.buildPath()}
          >
            <NavLink
              to={URLS.serviceManagement.mobile.cellularSIMCards.list.buildPath()}
            >
              Cellular SIM
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key={URLS.serviceManagement.mobile.cellularSIMCardChangeLogs.list.buildPath()}
          >
            <NavLink
              to={URLS.serviceManagement.mobile.cellularSIMCardChangeLogs.list.buildPath()}
            >
              Change Logs
            </NavLink>
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.Item key={URLS.serviceManagement.mobileVoice.key.buildPath()}>
          <NavLink
            to={URLS.serviceManagement.mobileVoice.simCards.list.buildPath()}
          >
            <Icon pro icon={'faMobile'} /> <span>Mobile Voice</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item
          key={URLS.serviceManagement.managedServices.list.buildPath()}
        >
          <NavLink to={URLS.serviceManagement.managedServices.list.buildPath()}>
            <Icon icon={'faNetworkWired'} /> <span>Managed Services</span>
          </NavLink>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu
        key={URLS.clientManagement.key.buildPath()}
        title={
          <span>
            <Icon icon={'faUsers'} />
            <span>Clients</span>
          </span>
        }
      >
        <Menu.Item key={URLS.clientManagement.companies.list.buildPath()}>
          <NavLink to={URLS.clientManagement.companies.list.buildPath()}>
            <Icon icon={'faBuilding'} /> <span>Companies</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key={URLS.clientManagement.contacts.list.buildPath()}>
          <NavLink to={URLS.clientManagement.contacts.list.buildPath()}>
            <Icon icon={'faUser'} /> <span>Contacts</span>
          </NavLink>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu
        key={URLS.changeManagement.key.buildPath()}
        title={
          <span>
            <Icon icon={'faCogs'} />
            <span>Change Management</span>
          </span>
        }
      >
        <Menu.Item key={URLS.changeManagement.changes.list.buildPath()}>
          <NavLink to={URLS.changeManagement.changes.list.buildPath()}>
            <Icon icon={'faEnvelope'} />
            <span>Notifications</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key={URLS.changeManagement.requests.list.buildPath()}>
          <NavLink to={URLS.changeManagement.requests.list.buildPath()}>
            <Icon icon={'faListAlt'} />
            <span>Requests</span>
          </NavLink>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu
        key={URLS.voiceBilling.key.buildPath()}
        title={
          <span>
            <Icon icon={'faPhone'} />
            <span>Voice</span>
          </span>
        }
      >
        <Menu.Item key={URLS.voiceBilling.clientReports.list.buildPath()}>
          <NavLink to={URLS.voiceBilling.clientReports.list.buildPath()}>
            <Icon icon={'faUsers'} pro /> <span>Client Reports</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key={URLS.voiceBilling.consolidatedReports.list.buildPath()}>
          <NavLink to={URLS.voiceBilling.consolidatedReports.list.buildPath()}>
            <Icon icon={'faReceipt'} pro /> <span>Consolidated Reports</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key={URLS.voiceBilling.reachReports.list.buildPath()}>
          <NavLink to={URLS.voiceBilling.reachReports.list.buildPath()}>
            <Icon icon={'faReceipt'} pro /> <span>Reach Reports</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key={URLS.voiceBilling.reportRuns.list.buildPath()}>
          <NavLink to={URLS.voiceBilling.reportRuns.list.buildPath()}>
            <Icon icon={'faHistory'} pro /> <span>Report Runs</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key={URLS.voiceBilling.jurisdictions.list.buildPath()}>
          <NavLink to={URLS.voiceBilling.jurisdictions.list.buildPath()}>
            <Icon icon={'faGlobeAsia'} /> <span>Jurisdictions</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key={URLS.voiceBilling.SIPTrunks.list.buildPath()}>
          <NavLink to={URLS.voiceBilling.SIPTrunks.list.buildPath()}>
            <Icon icon={'faPhoneOffice'} pro /> <span>SIP Trunks</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key={URLS.voiceBilling.phoneNumberRanges.list.buildPath()}>
          <NavLink to={URLS.voiceBilling.phoneNumberRanges.list.buildPath()}>
            <Icon icon={'faListOl'} /> <span>Phone Number Ranges</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key={URLS.voiceBilling.baseRateCards.list.buildPath()}>
          <NavLink to={URLS.voiceBilling.baseRateCards.list.buildPath()}>
            <Icon icon={'faMoneyCheckEdit'} pro /> <span>Base Rate Cards</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key={URLS.voiceBilling.customRateCards.list.buildPath()}>
          <NavLink to={URLS.voiceBilling.customRateCards.list.buildPath()}>
            <Icon icon={'faMoneyCheckEdit'} pro />{' '}
            <span>Custom Rate Cards</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key={URLS.voiceBilling.rateBundles.list.buildPath()}>
          <NavLink to={URLS.voiceBilling.rateBundles.list.buildPath()}>
            <Icon icon={'faClone'} pro /> <span>Rate Bundles</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key={URLS.voiceBilling.discountAgreements.list.buildPath()}>
          <NavLink to={URLS.voiceBilling.discountAgreements.list.buildPath()}>
            <Icon icon={'faPercentage'} pro /> <span>Discount Agreements</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key={URLS.voiceBilling.answerPoints.list.buildPath()}>
          <NavLink to={URLS.voiceBilling.answerPoints.list.buildPath()}>
            <Icon icon={'faPhone'} pro /> <span>Answer Points</span>
          </NavLink>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu
        key={URLS.reports.key.buildPath()}
        title={
          <span>
            <Icon icon={'faList'} />
            <span>Reports</span>
          </span>
        }
      >
        <Menu.Item key={URLS.reports.invoices.buildPath()}>
          <NavLink to={URLS.reports.invoices.buildPath()}>Invoices</NavLink>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu
        key={URLS.admin.key.buildPath()}
        title={
          <span>
            <Icon icon={'faWrench'} />
            <span>Admin</span>
          </span>
        }
      >
        <Menu.SubMenu
          key={URLS.admin.userAndGroups.key.buildPath()}
          title={'Users and Groups'}
        >
          <Menu.Item key={URLS.admin.userAndGroups.users.list.buildPath()}>
            <NavLink to={URLS.admin.userAndGroups.users.list.buildPath()}>
              Users
            </NavLink>
          </Menu.Item>
          <Menu.Item key={URLS.admin.userAndGroups.userGroups.list.buildPath()}>
            <NavLink to={URLS.admin.userAndGroups.userGroups.list.buildPath()}>
              User Groups
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key={URLS.admin.userAndGroups.permissions.list.buildPath()}
          >
            <NavLink to={URLS.admin.userAndGroups.permissions.list.buildPath()}>
              Permissions
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key={URLS.admin.userAndGroups.permissionsSets.list.buildPath()}
          >
            <NavLink
              to={URLS.admin.userAndGroups.permissionsSets.list.buildPath()}
            >
              Permissions Sets
            </NavLink>
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu
          key={URLS.admin.circuitsAndDevices.key.buildPath()}
          title={'Circuits and Devices'}
        >
          <Menu.Item
            key={URLS.admin.circuitsAndDevices.circuitTypes.list.buildPath()}
          >
            <NavLink
              to={URLS.admin.circuitsAndDevices.circuitTypes.list.buildPath()}
            >
              Circuit Types
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key={URLS.admin.circuitsAndDevices.deviceManufacturers.list.buildPath()}
          >
            <NavLink
              to={URLS.admin.circuitsAndDevices.deviceManufacturers.list.buildPath()}
            >
              Device Types
            </NavLink>
          </Menu.Item>

          <Menu.Item
            key={URLS.admin.circuitsAndDevices.deviceCategories.list.buildPath()}
          >
            <NavLink
              to={URLS.admin.circuitsAndDevices.deviceCategories.list.buildPath()}
            >
              Device Categories
            </NavLink>
          </Menu.Item>

          <Menu.Item
            key={URLS.admin.circuitsAndDevices.deviceTags.list.buildPath()}
          >
            <NavLink
              to={URLS.admin.circuitsAndDevices.deviceTags.list.buildPath()}
            >
              Device Tags Library
            </NavLink>
          </Menu.Item>

          <Menu.Item
            key={URLS.admin.circuitsAndDevices.bandwidths.list.buildPath()}
          >
            <NavLink
              to={URLS.admin.circuitsAndDevices.bandwidths.list.buildPath()}
            >
              Bandwidths
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key={URLS.admin.circuitsAndDevices.interfaceTypes.list.buildPath()}
          >
            <NavLink
              to={URLS.admin.circuitsAndDevices.interfaceTypes.list.buildPath()}
            >
              Interface Types
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key={URLS.admin.circuitsAndDevices.interfaceConfigurations.list.buildPath()}
          >
            <NavLink
              to={URLS.admin.circuitsAndDevices.interfaceConfigurations.list.buildPath()}
            >
              Interface Configurations
            </NavLink>
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.Item
          key={URLS.admin.provisioning.customerStatuses.list.buildPath()}
        >
          <NavLink
            to={URLS.admin.provisioning.customerStatuses.list.buildPath()}
          >
            Customer Statuses
          </NavLink>
        </Menu.Item>
        <Menu.SubMenu
          key={URLS.admin.vendorManagement.key.buildPath()}
          title={'Vendor Management'}
        >
          <Menu.Item
            key={URLS.admin.vendorManagement.carriers.list.buildPath()}
          >
            <NavLink to={URLS.admin.vendorManagement.carriers.list.buildPath()}>
              Carriers
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key={URLS.admin.vendorManagement.maintenanceOrganizations.list.buildPath()}
          >
            <NavLink
              to={URLS.admin.vendorManagement.maintenanceOrganizations.list.buildPath()}
            >
              Maintenance
            </NavLink>
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu
          key={URLS.admin.emailSettings.key.buildPath()}
          title={'Email Settings'}
        >
          <Menu.Item
            key={URLS.admin.emailSettings.emailSettings.list.buildPath()}
          >
            <NavLink
              to={URLS.admin.emailSettings.emailSettings.list.buildPath()}
            >
              Email Settings
            </NavLink>
          </Menu.Item>
          <Menu.Item key={URLS.admin.emailSettings.emails.list.buildPath()}>
            <NavLink to={URLS.admin.emailSettings.emails.list.buildPath()}>
              Email Tracking
            </NavLink>
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu
          key={URLS.admin.productManagement.key.buildPath()}
          title={'Product Management'}
        >
          <Menu.Item
            key={URLS.admin.productManagement.serviceCategories.list.buildPath()}
          >
            <NavLink
              to={URLS.admin.productManagement.serviceCategories.list.buildPath()}
            >
              Service Categories
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key={URLS.admin.productManagement.serviceTypes.list.buildPath()}
          >
            <NavLink
              to={URLS.admin.productManagement.serviceTypes.list.buildPath()}
            >
              Service Types
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key={URLS.admin.productManagement.handoverDocumentTemplates.list.buildPath()}
          >
            <NavLink
              to={URLS.admin.productManagement.handoverDocumentTemplates.list.buildPath()}
            >
              Handover Templates
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key={URLS.admin.productManagement.workPlanTemplates.list.buildPath()}
          >
            <NavLink
              to={URLS.admin.productManagement.workPlanTemplates.list.buildPath()}
            >
              Work Plan Templates
            </NavLink>
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu
          key={URLS.admin.connectwise.key.buildPath()}
          title={'ConnectWise'}
        >
          <Menu.Item key={URLS.admin.connectwise.syncEvents.list.buildPath()}>
            <NavLink to={URLS.admin.connectwise.syncEvents.list.buildPath()}>
              Sync Events
            </NavLink>
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu
          key={URLS.admin.voiceBilling.key.buildPath()}
          title={'Voice'}
        >
          <Menu.Item key={URLS.admin.voiceBilling.callTypes.list.buildPath()}>
            <NavLink to={URLS.admin.voiceBilling.callTypes.list.buildPath()}>
              Call Types
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key={URLS.admin.voiceBilling.callSubtypes.list.buildPath()}
          >
            <NavLink to={URLS.admin.voiceBilling.callSubtypes.list.buildPath()}>
              Call Subtypes
            </NavLink>
          </Menu.Item>
          <Menu.Item key={URLS.admin.voiceBilling.voiceCodecs.list.buildPath()}>
            <NavLink to={URLS.admin.voiceBilling.voiceCodecs.list.buildPath()}>
              Voice Codecs
            </NavLink>
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu
          key={URLS.admin.formBuilder.key.buildPath()}
          title={'Form Builder'}
        >
          <Menu.Item key={URLS.admin.formBuilder.templates.list.buildPath()}>
            <NavLink to={URLS.admin.formBuilder.templates.list.buildPath()}>
              Templates
            </NavLink>
          </Menu.Item>
          <Menu.Item key={URLS.admin.formBuilder.forms.list.buildPath()}>
            <NavLink to={URLS.admin.formBuilder.forms.list.buildPath()}>
              Forms
            </NavLink>
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu
          key={URLS.admin.operations.key.buildPath()}
          title={'Operations'}
        >
          <Menu.Item
            key={URLS.admin.operations.circuitRelocation.index.buildPath()}
          >
            <NavLink
              to={URLS.admin.operations.circuitRelocation.index.buildPath()}
            >
              Circuit Relocation
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key={URLS.admin.operations.tagManagement.index.buildPath()}
          >
            <NavLink to={URLS.admin.operations.tagManagement.index.buildPath()}>
              Tag Management
            </NavLink>
          </Menu.Item>
          <Menu.Item key={URLS.admin.serviceMessages.list.buildPath()}>
            <NavLink to={URLS.admin.serviceMessages.list.buildPath()}>
              Service Messages
            </NavLink>
          </Menu.Item>
        </Menu.SubMenu>
      </Menu.SubMenu>
    </Menu>
  )
}

SuperAdminMenuItems.propTypes = {
  defaultOpenKeys: PropTypes.array.isRequired,
  defaultSelectedKeys: PropTypes.array.isRequired,
  selectedKeys: PropTypes.array.isRequired,
  onOpenChange: PropTypes.func.isRequired,
  optionalMenuProps: PropTypes.object,
  onMenuItemClick: PropTypes.func.isRequired,
}

export default SuperAdminMenuItems
