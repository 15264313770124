import React, { useState } from 'react'

import { Alert, BackTop, Card, Layout, Modal } from 'antd'
import Helmet from 'react-helmet'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

import HeaderNavigation from 'containers/Nav/HeaderNavigation'
import MainNavigation from 'containers/Nav/MainNavigation'
import ActivityIntervalLogger from 'components/ActivityIntervalLogger'
import { LAYOUT_MODE, useLayout } from 'containers/Apps/LayoutContext'
import { useAuth } from 'containers/Auth/AuthContext'
import GoogleAnalytics from 'components/GoogleAnalytics'
import LoadingProgress from 'components/LoadingProgress'
import { MentionsContextProvider } from 'components/MentionsContext'
import ReleaseNotes from 'components/ReleaseNotes'
import { customerRoutes, noAccessRoutes, superAdminRoutes } from 'routes'
import { getEnvConfig } from 'env'
import { shadeColor } from 'utils/functions'

import styles from './index.module.less'
import { ServiceMessageContext } from 'components/Notifications/ServiceMessage'

const { Sider, Footer, Content } = Layout

const InternalApp = () => {
  const layout = useLayout()
  const { pathname } = useLocation()
  const {
    authToken,
    selectedMembership,
    company,
    idTokenValidating,
    idTokenValidationError,
  } = useAuth()
  const config = getEnvConfig()
  const isFormsPage = pathname.startsWith('/forms/')
  const [isSliderCollapsed, setIsSliderCollapsed] = useState(false)
  const [isReleaseNotesModalOpen, setIsReleaseNotesModalOpen] = useState(false)
  const [isServiceMessagePresent, setIsServiceMessagePresent] = useState(false)
  let router

  const toggleSlider = () => {
    setIsSliderCollapsed(!isSliderCollapsed)
  }

  if (authToken && selectedMembership.groupCodename) {
    const { groupCodename } = selectedMembership
    const { logo, primaryColor, secondaryColor, enableSupportSection } = company

    let head

    if (secondaryColor) {
      head = (
        <Helmet>
          <style type={'text/css'}>
            {`
                    .ant-btn-primary {
                        color: #fff;
                        background-color: ${secondaryColor};
                        border-color: ${secondaryColor};
                        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
                        -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
                        box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
                    }

                    .ant-btn-primary:hover, .ant-btn-primary:active, .ant-btn-primary:focus {
                        background-color: ${shadeColor(secondaryColor, -10)};
                        border-color: ${shadeColor(secondaryColor, -10)};
                        color: #fff;
                    }

                    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
                        background-color: ${shadeColor(secondaryColor, 0, 0.1)};
                    }

                    .ant-menu-item-selected > span > a, .ant-menu-item-selected > span  > a:hover {
                        color: ${secondaryColor} !important;
                    }

                    .ant-menu-item > a:hover {
                        color: ${secondaryColor};
                    }

                    .ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after {
                        border-color: ${secondaryColor};
                    }

                    .ant-menu-horizontal > .ant-menu-item:hover,
                    .ant-menu-horizontal > .ant-menu-submenu:hover,
                    .ant-menu-horizontal > .ant-menu-item-active,
                    .ant-menu-horizontal > .ant-menu-submenu-active,
                    .ant-menu-horizontal > .ant-menu-item-open,
                    .ant-menu-horizontal > .ant-menu-submenu-open,
                    .ant-menu-horizontal > .ant-menu-item-selected,
                    .ant-menu-horizontal > .ant-menu-submenu-selected {
                        color: ${secondaryColor};
                        border-color: ${secondaryColor};
                    }

                    .top-navigation .ant-menu-horizontal .ant-menu-submenu-title {
                        color: #fff !important;
                        font-weight: 400;
                    }

                    .top-navigation .ant-menu-horizontal .ant-menu-submenu-title:hover, .top-navigation .ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
                        background: ${shadeColor(
                          secondaryColor,
                          50,
                          0.2
                        )} !important;
                    }


                    .ant-menu-item:hover,
                    .ant-menu-submenu:hover,
                    .ant-menu-item-active,
                    .ant-menu-submenu-active,
                    .ant-menu-item-open,
                    .ant-menu-submenu-open,
                    .ant-menu-item-selected,
                    .ant-menu-submenu-selected {
                        color: ${secondaryColor} !important;
                        border-color: ${secondaryColor} !important;
                    }

                    .ant-menu-light .ant-menu-item:hover, .ant-menu-light .ant-menu-item-active, .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-light .ant-menu-submenu-active, .ant-menu-light .ant-menu-submenu-title:hover {
                      color: ${secondaryColor} !important;
                    }
                    .ant-menu-sub .ant-menu-item a {
                        color: #000 !important;
                    }

                    .ant-switch-checked {
                      background-color: ${secondaryColor};
                    }

                    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
                      border-color: ${secondaryColor};
                    }

                    .ant-menu-item a:hover,
                    .ant-spin {
                      color: ${secondaryColor} !important;
                    }
                    `}
          </style>
        </Helmet>
      )
    }

    if (groupCodename === 'NO_ACCESS') {
      router = (
        <Layout>
          {head && head}
          {noAccessRoutes}
        </Layout>
      )
    } else {
      router = (
        <MentionsContextProvider>
          <ActivityIntervalLogger />
          <GoogleAnalytics />
          {isReleaseNotesModalOpen && (
            <Modal
              visible
              destroyOnClose
              title={
                <div>
                  <div className="logo" />
                  <div className={styles.releaseNotesModalTitle}>
                    Release Notes
                  </div>
                </div>
              }
              className={styles.releaseNotesModal}
              width={1200}
              onCancel={() => setIsReleaseNotesModalOpen(false)}
              footer={null}
            >
              <ReleaseNotes
                section={
                  groupCodename === 'SUPERADMIN' ? 'STAFF_UI' : 'CUSTOMER_UI'
                }
              />
            </Modal>
          )}
          <Layout>
            {head && head}
            {layout.mode === LAYOUT_MODE.DEFAULT && !isFormsPage && (
              <ServiceMessageContext.Provider
                value={{ isServiceMessagePresent, setIsServiceMessagePresent }}
              >
                <HeaderNavigation backgroundColor={primaryColor} />
              </ServiceMessageContext.Provider>
            )}
            <Layout
              style={{ paddingTop: isServiceMessagePresent ? '40px' : 0 }}
            >
              {layout.mode === LAYOUT_MODE.DEFAULT && !isFormsPage && (
                <Sider
                  width={260}
                  collapsible
                  theme="light"
                  collapsed={isSliderCollapsed}
                  onCollapse={toggleSlider}
                  className="sidebar"
                  style={{
                    paddingTop: isServiceMessagePresent ? '40px' : '0px',
                  }}
                >
                  <MainNavigation
                    enableSupportSection={
                      enableSupportSection &&
                      !['GUEST', 'NO_ACCESS'].includes(groupCodename)
                    }
                  />
                  {logo && (
                    <div className={'sider-company-logo'}>
                      <img src={logo} alt={'logo'} />
                    </div>
                  )}
                </Sider>
              )}
              <Layout
                style={{
                  marginLeft:
                    layout.mode === LAYOUT_MODE.DEFAULT && !isFormsPage
                      ? isSliderCollapsed
                        ? 80
                        : 260
                      : 0,
                  paddingTop:
                    layout.mode === LAYOUT_MODE.DEFAULT && !isFormsPage
                      ? 64
                      : 0,
                }}
              >
                <Content className={'top-content-wrapper'}>
                  {groupCodename === 'SUPERADMIN' && superAdminRoutes}
                  {(groupCodename === 'ADMIN' ||
                    groupCodename === 'USER' ||
                    groupCodename === 'GUEST') && <>{customerRoutes}</>}
                  {groupCodename === 'NO_ACCESS' && noAccessRoutes}
                </Content>
                {layout.mode === LAYOUT_MODE.DEFAULT && !isFormsPage && (
                  <Footer
                    className={'footer'}
                    style={{
                      left: isSliderCollapsed ? 80 : 260,
                    }}
                  >
                    Copyright &copy; Network Solutions Group Pty Limited. App
                    Version {config.appVersion}.{' '}
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault()
                        setIsReleaseNotesModalOpen(true)
                      }}
                    >
                      Release notes
                    </a>
                    .
                  </Footer>
                )}
                <BackTop style={{ right: 30 }} />
              </Layout>
            </Layout>
          </Layout>
        </MentionsContextProvider>
      )
    }
  } else {
    if (idTokenValidating) {
      return <LoadingProgress />
    }

    if (idTokenValidationError) {
      return (
        <Card
          title={'Authorization error'}
          style={{ width: 600, margin: '40px auto' }}
        >
          <Alert
            message={
              <span>
                Unable authorize user:{' '}
                {idTokenValidationError.token &&
                  idTokenValidationError.token.join(', ')}
                {idTokenValidationError.non_field_errors &&
                  idTokenValidationError.non_field_errors.join(', ')}
              </span>
            }
            showIcon
            type="error"
          />
        </Card>
      )
    }

    router = (
      <div>
        <LoadingProgress />;
      </div>
    )
  }

  return router
}

InternalApp.propTypes = {
  jwtAccessToken: PropTypes.string,
}

export default InternalApp
