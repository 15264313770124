export const APP_TITLE = 'NetPortal'

export const ON_OBJECT_POST_SAVE = 'on_object_post_save'
export const ON_OBJECT_POST_DELETE = 'on_object_post_delete'

export const ON_ORDER_COMMENT_ACK_POST_SAVE = 'on_order_comment_ack_post_save'
export const ON_ORDER_COMMENT_POST_DELETE = 'on_order_comment_post_delete'
export const ON_ORDER_COMMENT_POST_SAVE = 'on_order_comment_post_save'

export const ON_MENTION_ACK_POST_SAVE = 'on_mention_ack_post_save'

export const HANDLE_ICON =
  'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z'

export const ECHARTS_COLOR_PALETTE = [
  '#1a3dd8',
  '#499e42',
  '#d79470',
  '#a40e1e',
  '#00376e',
  '#efcf3c',
  '#787464',
  '#cc5b16',
  '#79c8ff',
  '#9aafb9',
]

export const ECHARTS_TOOLBOX = {
  right: '0px',
  top: '20px',
  feature: {
    dataZoom: { yAxisIndex: 'none', title: 'Zoom' },
    dataView: {
      show: true,
      title: 'Data View',
      readOnly: false,
      lang: ['Data View', 'Close', 'Refresh'],
    },
    restore: { show: true, title: 'Reset' },
    saveAsImage: { show: true, title: 'Download' },
  },
}

export const ECHARTS_HANDLE_STYLE = {
  color: '#fff',
  shadowBlur: 3,
  shadowColor: 'rgba(0, 0, 0, 0.6)',
  shadowOffsetX: 2,
  shadowOffsetY: 2,
}

export const ECHARTS_DATA_ZOOM = [
  {
    type: 'inside',
    start: 0,
    end: 100,
  },
  {
    start: 0,
    end: 100,
    handleIcon: HANDLE_ICON,
    handleSize: '80%',
    handleStyle: ECHARTS_HANDLE_STYLE,
  },
]

export const ECHARTS_GRID = {
  left: '50px',
  right: '50px',
}

export const ECHARTS_TOOLTIP = {
  trigger: 'axis',
}

export const ECHARTS_CHECKBOX =
  'path://M400 480H48c-26.51 0-48-21.49-48-48V80c0-26.51 21.49-48 48-48h352c26.51 0 48 21.49 48 48v352c0 26.51-21.49 48-48 48zm-204.686-98.059l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.248-16.379-6.249-22.628 0L184 302.745l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.25 16.379 6.25 22.628.001z'

export const EMPTY_TEXT = '- -'
