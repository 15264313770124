import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import * as Sentry from '@sentry/browser'

import { ConfigProvider } from 'antd'
import en_US from 'antd/lib/locale-provider/en_US'
import moment from 'moment'
import { PublicClientApplication } from '@azure/msal-browser'
import {
  MsalProvider,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react'

import { LayoutContextProvider } from 'containers/Apps/LayoutContext'
import { AppContextProvider } from 'containers/Apps/AppContext'
import { AuthContextProvider } from 'containers/Auth/AuthContext'
import InternalApp from 'containers/Apps/InternalApp'
import PublicApp from 'containers/Apps/PublicApp'
import LocationCatcher from 'containers/Auth/LocationCatcher'
import { getEnvConfig } from 'env'

import 'moment/locale/es-us'

import './static/styles/theme.less'

import './static/styles/base.less'
import './static/styles/layout.less'
import './static/styles/datatables.less'
import './static/styles/modals.less'
import './static/styles/forms.less'
import './static/styles/nav.less'
import './static/styles/typo.less'
import './static/styles/reset.less'
import './static/styles/draganddrop.less'
import './static/styles/animations.less'

import './static/styles/auth/base.less'
import './static/styles/dashboard/base.less'
import './static/styles/support/base.less'
import './static/styles/services/service_comments.less'
import './static/styles/sim_cards/sim_cards.less'
import './static/styles/orders/terminate.less'

moment.locale('en')

const isBrowserTest = localStorage.getItem('is_browser_test') === 'true'
const envConfig = getEnvConfig()
const disableAzureAuth = isBrowserTest && envConfig.environment === 'local'
const publicClientApplication = new PublicClientApplication(envConfig.azureAuth)

if (envConfig.sentryDSN) {
  Sentry.init({ dsn: envConfig.sentryDSN })
}

ReactDOM.render(
  <BrowserRouter>
    <LocationCatcher />
    <LayoutContextProvider>
      <ConfigProvider locale={en_US}>
        <AppContextProvider>
          {disableAzureAuth ? (
            <AuthContextProvider>
              <InternalApp />
            </AuthContextProvider>
          ) : (
            <MsalProvider instance={publicClientApplication}>
              <AuthContextProvider>
                <AuthenticatedTemplate>
                  <InternalApp />
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                  <PublicApp />
                </UnauthenticatedTemplate>
              </AuthContextProvider>
            </MsalProvider>
          )}
        </AppContextProvider>
      </ConfigProvider>
    </LayoutContextProvider>
  </BrowserRouter>,
  document.getElementById('root')
)
