import React, { useEffect, useState } from 'react'

import { SearchOutlined } from '@ant-design/icons'
import { List, Skeleton, Input } from 'antd'
import _ from 'lodash'

import ColoredAvatar from 'components/ColoredAvatar'
import { useMentions } from 'components/MentionsContext'
import { formatMomentDateTime } from 'utils/functions'

const MentionsHistory = () => {
  const mentions = useMentions()
  const { getHistoricalMentions, historical } = mentions
  const [filteredMentions, setFilteredMentions] = useState()

  useEffect(() => {
    getHistoricalMentions()
  }, [getHistoricalMentions])

  useEffect(() => {
    setFilteredMentions(historical)
  }, [historical])

  if (!mentions.historical) {
    return (
      <>
        <Skeleton active />
        <Skeleton active />
        <Skeleton active />
      </>
    )
  }

  return (
    <>
      <Input
        placeholder="Quick filter"
        suffix={<SearchOutlined />}
        onChange={(e) => {
          const filter = e.target.value.toLowerCase()
          if (filter) {
            setFilteredMentions(
              historical.filter((mention) => {
                return (
                  _.includes(
                    mention.mentioned_by__full_name.toLowerCase(),
                    filter
                  ) ||
                  _.includes(mention.content_object__str.toLowerCase(), filter)
                )
              })
            )
          } else {
            setFilteredMentions(historical)
          }
        }}
      />
      <List
        dataSource={filteredMentions}
        pagination={false}
        renderItem={(item) => {
          return (
            <List.Item key={item.id}>
              <List.Item.Meta
                avatar={<ColoredAvatar value={item.mentioned_by__full_name} />}
                title={
                  <a
                    href={mentions.getHref(item)}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {item.mentioned_by__full_name} mentioned you in{' '}
                    <strong>{item.content_object__str}</strong>
                  </a>
                }
                description={formatMomentDateTime(item.date_created)}
              />
            </List.Item>
          )
        }}
      />
    </>
  )
}

export default MentionsHistory
