import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Icons from '@fortawesome/free-solid-svg-icons'
import * as ProIcons from '@fortawesome/pro-solid-svg-icons'
import * as BrandIcons from '@fortawesome/free-brands-svg-icons'

const Icon = (props) => {
  const { icon, style, pro, brand, ...restProps } = props

  let iconStyle

  if (!style) {
    iconStyle = { position: 'relative', top: -1 }
  } else {
    iconStyle = style
  }

  return (
    <i className="anticon" style={iconStyle} {...restProps}>
      <FontAwesomeIcon
        icon={pro ? ProIcons[icon] : brand ? BrandIcons[icon] : Icons[icon]}
      />
    </i>
  )
}

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  style: PropTypes.object,
  pro: PropTypes.bool,
  brand: PropTypes.bool,
}

export default Icon
