import React, { useContext, useState } from 'react'

import { NavLink, withRouter } from 'react-router-dom'
import {
  BellOutlined,
  ReloadOutlined,
  LogoutOutlined,
  ToolOutlined,
} from '@ant-design/icons'
import {
  Layout,
  Menu,
  Button,
  Badge,
  notification,
  Drawer,
  BackTop,
  Modal,
} from 'antd'
import PropTypes from 'prop-types'

import ColoredAvatar from 'components/ColoredAvatar'
import Icon from 'components/Icon'
import { useAuth } from 'containers/Auth/AuthContext'
import { WebSocketContextProvider } from 'components/Data/WebSocketContext'
import { useMentions } from 'components/MentionsContext'
import { useApp } from 'containers/Apps/AppContext'
import UserNotifications from 'components/UserNotifications'
import { DATA_URLS, URLS } from 'urls'
import { ON_OBJECT_POST_SAVE } from 'utils/constants'

import CompanySwitcher from './CompanySwitcher'

import styles from './index.module.less'
import ServiceMessage, {
  ServiceMessageContext,
} from 'components/Notifications/ServiceMessage'

const { Header } = Layout

const HeaderNavigation = ({ location, backgroundColor }) => {
  const { selectedMembership, reLoginAsSelf, user, loginOwner, membership } =
    useAuth()
  const mentions = useMentions()
  const { config } = useApp()
  const [userNotificationsDrawerOpen, setUserNotificationsDrawerOpen] =
    useState(false)
  const [companySwitcherVisible, setCompanySwitcherVisible] = useState(false)
  const [versionAlertVisible, setVersionAlertVisible] = useState(false)
  const optionalThemeStyles = {}
  const { isServiceMessagePresent } = useContext(ServiceMessageContext)

  let selectedPathName = ''
  let pathNameParts = location.pathname.split('/')

  if (pathNameParts.length >= 2) {
    selectedPathName = `/${pathNameParts[1]}`
  }

  const performReLoginAsSelf = () => {
    reLoginAsSelf({
      onSuccess: () => {
        document.location.href = URLS.dashboard.index.buildPath()
      },
    })
  }

  const onMentionsWSMessage = (msg) => {
    if (msg.created && msg.object.user === user.id) {
      const audio = new Audio('/notification.mp3')
      audio.play()

      switch (msg.type) {
        case ON_OBJECT_POST_SAVE:
          const key = `unreadMention${Date.now()}`

          notification.info({
            key: key,
            message: `${msg.object.mentioned_by__full_name} mentioned you in ${msg.object.content_object__str}`,
            btn: (
              <a
                href={mentions.getHref(msg.object)}
                target="_blank"
                rel="noreferrer noopener"
                className="ant-btn ant-btn-primary"
              >
                View
              </a>
            ),
          })
          break
        default:
          break
      }
    }
  }

  const onVersionWSMessage = (msg) => {
    console.info(`App version: ${msg.version}`)
    if (
      config.appVersion &&
      msg.version &&
      config.appVersion !== msg.version &&
      msg.version.indexOf('beta') === -1
    ) {
      setVersionAlertVisible(true)
    } else {
      setVersionAlertVisible(false)
    }
  }

  if (backgroundColor) {
    optionalThemeStyles.backgroundColor = `${backgroundColor}`
  }
  return (
    <>
      <Drawer
        id="id-user-notifications-drawer"
        visible={userNotificationsDrawerOpen}
        title="Notifications"
        destroyOnClose
        closable
        placement="right"
        width={500}
        onClose={() => setUserNotificationsDrawerOpen(false)}
      >
        <UserNotifications />
        <BackTop
          style={{ right: 40 }}
          target={() =>
            document
              .getElementById('id-user-notifications-drawer')
              .getElementsByClassName('ant-drawer-body')[0]
          }
        />
      </Drawer>
      {companySwitcherVisible && (
        <Modal
          title="Switch Organisation"
          visible
          closable
          onCancel={() => setCompanySwitcherVisible(false)}
          width={800}
          footer={[
            <Button onClick={() => setCompanySwitcherVisible(false)}>
              Cancel
            </Button>,
          ]}
        >
          <CompanySwitcher />
        </Modal>
      )}
      <ServiceMessage />
      <Header
        className="header"
        style={{
          position: 'fixed',
          top: isServiceMessagePresent ? '40px' : '0px',
          zIndex: 100,
          width: '100%',
          ...optionalThemeStyles,
        }}
      >
        <div className="logo" style={{ ...optionalThemeStyles }} />

        <WebSocketContextProvider
          url={DATA_URLS.generic.ws.version.buildPath()}
          onMessage={onVersionWSMessage}
        >
          <WebSocketContextProvider
            url={
              selectedMembership.groupCodename === 'SUPERADMIN'
                ? DATA_URLS.internal.mentions.ws.unread.buildPath()
                : null
            }
            onMessage={onMentionsWSMessage}
          >
            <div className="top-navigation">
              <Menu
                mode="horizontal"
                theme="dark"
                disabledOverflow
                style={{ float: 'right', ...optionalThemeStyles }}
                selectedKeys={[selectedPathName]}
              >
                {versionAlertVisible && (
                  <Menu.Item>
                    <Button
                      icon={<ReloadOutlined />}
                      type="danger"
                      onClick={() => {
                        window.location.reload()
                      }}
                    >
                      New version available
                    </Button>
                  </Menu.Item>
                )}
                {loginOwner && loginOwner.username && (
                  <Menu.Item>
                    <Button
                      onClick={performReLoginAsSelf}
                      icon={<LogoutOutlined />}
                      ghost
                    >
                      Re-Login as {loginOwner.fullName || loginOwner.username}
                    </Button>
                  </Menu.Item>
                )}

                {selectedMembership.groupCodename === 'SUPERADMIN' && (
                  <Menu.Item
                    key="notifications"
                    onClick={() => setUserNotificationsDrawerOpen(true)}
                  >
                    <span>
                      <Badge
                        count={mentions.unread && mentions.unread.length}
                        style={{ boxShadow: 'none', color: '#fff' }}
                      >
                        <BellOutlined
                          style={{ color: '#fff', marginRight: 0 }}
                        />
                      </Badge>
                    </span>
                  </Menu.Item>
                )}
                {(selectedMembership.groupCodename === 'ADMIN' ||
                  selectedMembership.groupCodename === 'USER' ||
                  selectedMembership.groupCodename === 'GUEST') && (
                  <Menu.SubMenu
                    style={{ lineHeight: '64px' }}
                    title={
                      <span className="submenu-title-wrapper">
                        <Icon icon="faBuilding" />
                        {selectedMembership.companyName}
                      </span>
                    }
                  >
                    <Menu.Item
                      key={URLS.customerUI.companyProfile.index.buildPath()}
                    >
                      <NavLink
                        to={URLS.customerUI.companyProfile.index.buildPath()}
                      >
                        Profile
                      </NavLink>
                    </Menu.Item>
                    {membership.length > 1 && (
                      <Menu.Item>
                        <a onClick={() => setCompanySwitcherVisible(true)}>
                          Switch Organisation
                        </a>
                      </Menu.Item>
                    )}
                  </Menu.SubMenu>
                )}
                {(selectedMembership.groupCodename === 'ADMIN' ||
                  selectedMembership.groupCodename === 'USER' ||
                  selectedMembership.groupCodename === 'GUEST') && (
                  <Menu.SubMenu
                    title={
                      <span className="submenu-title-wrapper">
                        <ToolOutlined />
                        Tools
                      </span>
                    }
                  >
                    <Menu.Item key="looking-glass">
                      <a
                        href="https://lg.netsg.net.au"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Looking Glass
                      </a>
                    </Menu.Item>
                    <Menu.Item key="insight-monitoring">
                      <a
                        href="https://insight.netsg.net.au"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Data Insight
                      </a>
                    </Menu.Item>
                    <Menu.Item key="forti-analyzer">
                      <a
                        href="https://analyzer.secure-sdwan.net"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        FortiAnalyzer
                      </a>
                    </Menu.Item>
                    <Menu.Item key="speedtest">
                      <a
                        href="https://www.speedtest.net/server/30430"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Speed Test
                      </a>
                    </Menu.Item>
                    <Menu.Item key="ddos">
                      <a
                        href="https://netsg.my.kentik.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        DDoS Protect Portal
                      </a>
                    </Menu.Item>
                  </Menu.SubMenu>
                )}
                <Menu.SubMenu
                  style={{ lineHeight: '64px' }}
                  title={
                    <span className="submenu-title-wrapper">
                      <ColoredAvatar
                        value={user.fullName}
                        showTooltip={false}
                      />
                    </span>
                  }
                >
                  {selectedMembership.groupCodename === 'SUPERADMIN' &&
                    membership.length > 1 && (
                      <Menu.Item>
                        <a onClick={() => setCompanySwitcherVisible(true)}>
                          Switch Organisation
                        </a>
                      </Menu.Item>
                    )}
                  <Menu.Item className={styles.userInfoMenuItem}>
                    <div className={styles.fullNameWrapper}>
                      {user.fullName}
                    </div>
                    <div className={styles.permissionWrapper}>
                      {selectedMembership.groupCodename}
                    </div>
                  </Menu.Item>
                  <Menu.Item key={URLS.account.logout.buildPath()}>
                    <NavLink to={URLS.account.logout.buildPath()}>
                      Logout
                    </NavLink>
                  </Menu.Item>
                </Menu.SubMenu>
              </Menu>
            </div>
          </WebSocketContextProvider>
        </WebSocketContextProvider>
      </Header>
    </>
  )
}

HeaderNavigation.propTypes = {
  location: PropTypes.object.isRequired,
  backgroundColor: PropTypes.string,
}

export default withRouter(HeaderNavigation)
