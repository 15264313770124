import React, { useContext } from 'react'

import ReactInterval from 'react-interval'

import { AuthContext } from 'containers/Auth/AuthContext'
import { useApp } from 'containers/Apps/AppContext'

const ActivityIntervalLogger = () => {
  const authContext = useContext(AuthContext)
  const { config } = useApp()
  const logActivity = () => {
    authContext.logActivity()
  }

  if (!config.trackUserActivity) {
    return null
  }

  return <ReactInterval timeout={5000} enabled callback={logActivity} />
}

export default ActivityIntervalLogger
